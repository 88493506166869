<template>
  <section class="main-section main-section-banquet">
    <v-img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/banner/banner-banquet.png"></v-img>
    <div id="common-form-layout">
      <div>
        <h3 class="banquet-name-div">
          <span class="banquet-name">预约家宴</span>
        </h3>
      </div>
      <v-form
        v-model="messageForm"
        ref="messageForm"
        style="width:100%; margin-top: 2.5rem;"
      >
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-subheader class="ctk-input-label">您的姓名</v-subheader>
          </v-col>
          <v-col cols="12" xs="12" md="12">
            <v-text-field name="name" placeholder="请输入您的姓名" filled rounded label="" :rules="[v => !!v || '请输入您的姓名']" v-model="formData.name"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12"  xs="12" md="12">
            <v-subheader class="ctk-input-label">联系电话</v-subheader>
          </v-col>
          <v-col cols="4" xs="4" md="4">
            <v-text-field height="3.5rem" name="mobile" placeholder="请输入手机号" filled rounded label="" :rules="[ v => !!v || '请输入您的手机号', v => /^[1][0-9]{10}$/.test(v) || '请输入正确的手机号']" v-model="formData.mobile" ></v-text-field>
          </v-col>
          <v-col cols="4"  xs="4" md="4" style="padding-left: 1rem;">
            <v-text-field height="3.5rem" name="code" placeholder="验证码" filled rounded label="" :rules="[ v => !!v || '请输入验证码', v => /(^[\-0-9][0-9]*(.[0-9]+)?)$/.test(v) || '验证码错误']" v-model="formData.code" > </v-text-field>
          </v-col>
          <v-col cols="4"  xs="4" md="4">
            <a id="send_code_btn" @click="sendCode">获取验证码</a>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-subheader class="ctk-input-label">家宴日期</v-subheader>
          </v-col>
          <v-col cols="12" xs="12" md="12">
            <v-text-field name="ymd" placeholder="请输入家宴日期" filled rounded label="" :rules="[v => !!v || '请输入家宴日期']" v-model="formData.ymd"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-subheader class="ctk-input-label">家宴人数</v-subheader>
          </v-col>
          <v-col cols="12" xs="12" md="12">
            <v-text-field name="num" placeholder="请输入家宴人数" filled rounded label="" :rules="[v => !!v || '请输入家宴人数']" v-model="formData.num"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-subheader class="ctk-input-label">家宴菜单 <span>（下滑至底部了解菜单详情）</span></v-subheader>
          </v-col>
          <v-col cols="12" xs="12" md="12">
            <v-radio-group
              v-model="formData.menu"
              row
              :rules="[v => !!v || '请选择家宴菜单']"
            >
              <v-radio
                label="MENU A"
                value="A"
                color="rgba(35, 24, 21, 1)"
              ></v-radio>
              <v-radio
                label="MENU B"
                value="B"
                color="rgba(35, 24, 21, 1)"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-subheader class="ctk-input-label" style="margin-top: 1rem;">消费凭证</v-subheader>
          </v-col>
          <v-col cols="12" xs="12" md="12">
            <v-file-input
              class="banquet-attach"
              filled rounded
              :clearable="false"
              placeholder="点击上传"
              @change="fileUpload"
              :rules="[v => !!v || '请上传消费凭证']" v-model="formData.attach"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" xs="12" md="12">
            <v-btn depressed tile class="submit-btn" @click="submitForm" width="100%" height="3.5rem">提交</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">操作提示</v-card-title>
              <v-card-text>{{returnMsg}}</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="dialog = false" >确定</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </div>
    <div class="main-layout">
      <header class="banquet-header-img"></header>
      <div class="banquet-section">
        <div class="banquet-rules" v-for="(c, ri) in rules" :key="ri">
          <h3 class="banquet-rule-title">{{ c.title }}</h3>
          <div class="banquet-rule-content" v-html="c.content"></div>
        </div>
        <div class="banquet-remark">{{ remark }}</div>
        <div class="banquet-menus" v-for="(c, ci) in menus" :key="'c' + ci">
          <v-img class="banquet-menu-title" :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/' + c.title"></v-img>
          <div v-for="(m, mi) in c.items" :key="'m' + mi">
            <h3 class="banquet-menu-name">{{ m.name }}</h3>
            <div class="banquet-menu-content" v-html="m.content"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  data: () => ({
    rules: [
      {
        title: '家宴参与说明',
        content: '<p>凡购买玛娅家居单张发票实付金额满30万元可参与家宴服务活动一次，自发票开具日期起半年内有效。</p><p>敬请至玛娅家居授权门店预约登记，并关注微信"德国玛娅家居"官微"私享家宴"板块，参与家宴活动。</p><p>2套菜单可选，原上海半岛酒店Chef提供服务</p>'
      },
      {
        title: '家宴预约说明',
        content: '<p>参与微信端"私享家宴"预约，每场家宴服务需提前2周预约。<p>上传您的消费发票照片，选择时间、人数、菜单。</p><p>您将于2个工作日内收到预约回复。</p>'
      },
      {
        title: '家宴服务反馈',
        content: '<p>感谢您参与玛娅家居的家宴活动，在享受完家宴服务后留下您宝贵的意见，我们的厨师团队将给你提供一份精美小礼品。</p>'
      }
    ],
    remark: '★ 本活动仅限上海地区',
    menus: [
      {
        title: 'banquet-menu-a.png',
        items: [
          {
            name: 'Appetizer',
            content: '<p>Pan-Fried Australia Scallop</p><p>Pan-Fried Australia Scallop and Tomato puree with Pot Wine Sauce</p><p>香煎澳大利亚鲜带子配自制低温番茄和港口葡萄酒汁</p>'
          },
          {
            name: 'Soap',
            content: '<p>Chicken Consomme with Homemade Gnocchi</p><p>鸡肉清汤配自制意大利土豆团</p>'
          },
          {
            name: 'PASTA',
            content: '<p>Classics Seafood Risotto with marinara Sauce</p><p>经典海鲜意大利饭配蒜香番茄酱</p>'
          },
          {
            name: 'Main Course',
            content: '<p>Beef Wilington with Black Truffle Sauce and Grilled Seasonal Vegetable</p><p>惠灵顿牛肉配松露汁和碳烤时令蔬菜</p>'
          },
          {
            name: 'Or',
            content: '<p>Oven Roasted Salmon Fillet with Grilled Seasonal Vegetable and Mustard Cream Sauce</p><p>大西洋三文鱼柳-碳烤三文鱼配碳烤时蔬和芥末奶油汁</p>'
          },
          {
            name: 'Dessert',
            content: '<p>Classics Soft Tiramisu 经典意式提拉米苏</p>'
          }
        ]
      },
      {
        title: 'banquet-menu-b.png',
        items: [
          {
            name: 'Appetizer',
            content: '<p>Mixed Antipasto</p><p>(Paris Ham, Parma Ham, Salami, Balsamic Mush-room, parmesan Cheese, Artichoke)</p><p>美味餐前拼盆，多种意大利风味的腌制肉食和蔬菜，新鲜干酪</p>'
          },
          {
            name: 'Sopu',
            content: '<p>Minestrone soup</p><p>意大利蔬菜汤</p>'
          },
          {
            name: 'Pasta',
            content: '<p>Linguine seafood with spicy tomato sauce</p><p>扁平实心长面配各式海鲜和辣味番茄酱</p>'
          },
          {
            name: 'Main Course',
            content: '<p>Roast beef tenderloin with pan fried goose liver and truffle sauce</p><p>碳烤牛柳配香煎鹅肝和松露汁</p>'
          },
          {
            name: 'Or',
            content: '<p>Mediterranean-style mix gilled seafood</p><p>地中海式碳烤多种海鲜</p>'
          },
          {
            name: 'Dessert',
            content: '<p>Homemade Yoghourt Pudding with Mix Berry Soup</p><p>自制酸奶补丁配混合野梅汤</p>'
          }
        ]
      }
    ],
    messageForm: false,
    returnMsg: null,
    dialog: false,
    formData: {
      name: '',
      mobile: '',
      code: '',
      ymd: '',
      num: '',
      menu: '',
      attach: null
    },
    btnDisable: false,
    itv: '',
    scd: 60
  }),
  created () {
    this.$store.commit('updateActiveNav', null)
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
    fileUpload (file) {
      this.attach = file
    },
    sendCode () {
      if (this.btnDisable) {
        return false
      }
      if (/^[1][0-9]{10}$/.test(this.formData.mobile)) {
        this.btnDisable = true
        document.getElementById('send_code_btn').innerText = '发送中...'
        var that = this
        this.https.get('join_code', { mobile: this.formData.mobile }).then(response => {
          if (response.code === 0) {
            that.itv = setInterval(function () {
              if (that.scd > 1) {
                that.scd -= 1
                document.getElementById('send_code_btn').innerText = '等待' + that.scd + '(s)'
              } else {
                that.btnDisable = false
                document.getElementById('send_code_btn').innerText = '发送验证码'
                that.scd = 60
                clearInterval(that.itv)
              }
            }, 1000)
          } else {
            that.btnDisable = false
            document.getElementById('send_code_btn').innerText = '发送验证码'
            that.scd = 60
            this.returnMsg = response.msg
            this.dialog = true
          }
        })
      } else {
        this.returnMsg = '请输入正确的手机号码'
        this.dialog = true
      }
    },
    submitForm () {
      this.$refs.messageForm.validate()
      if (this.messageForm === true) {
        let formData = new FormData()
        formData.append('attach', this.formData.attach)
        for (let v in this.formData) {
          formData.append(v, this.formData[v])
        }
        this.https.upload('online_banquet', formData).then(response => {
          if (response.code === 0) {
            this.$refs.messageForm.reset()
            document.getElementById('send_code_btn').innerText = '发送验证码'
          }
          this.returnMsg = response.msg
          this.dialog = true
        })
      }
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/about.css';
</style>
